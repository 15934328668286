import React from "react"
import styled from 'styled-components';
import Layout from '../components/layout';
import { graphql } from 'gatsby';
import SEO from "../components/seo"
import dotIcon from 'images/dot-icon.svg';
import {

  InnerContainer,
  Spacer,
  Column,
  Grid,
  Media,
} from 'components/ui';
import {
  HelpHero,
  AboutOptions,
  HelpToBuySlider,
  SinglePropertyAdvice,
} from 'components/sections';
import sc from 'config/theme';
import $ from 'jquery';
import { Script, Sans, Serif } from 'Typography';
import bp from 'config/responsive';
import { StampDutyCalculator } from 'components/blocks';
import sellPageMotion from 'motion/sell.js';

import { ViewportEnterAnimation } from 'components/ui';
import { functionAboutAccordion, functionWhiteBox1 } from 'motion/about.js';

const Container = styled.div`
  background: ${sc.colors.white};
  padding-top: 0px;
  /* padding-bottom: 100px; */

  & .aboutOptions {
    opacity: 1 !important;
  }
  &&& .no-margin {
    margin-bottom: 0 !important;
  }
  & .content-in {
    max-width: 1540px;
    margin: auto;
  }
  .whiteBG {
    background: #fff !important;
  }
  & .transparentBG {

    ${bp.computer} {
      margin: -85px 0;
    }

    & > div {
      background: transparent;
      padding-top: 0;
      padding-bottom: 0;
    }

    .ifNoMenu {
      display: none;

    }
  }

  ${bp.computer} {
    padding-top: 0px
  }

  & .stampMaxWidth {
    max-width: 95%;
    margin: auto;

    ${bp.computer} {
      max-width: 1200px;
    }
  }


  & h2 {
    color: #0B1D3F;
    margin: 30px 0;
  }

  & .table{
    display: table;
    width: 100%;
    max-width: 800px;
    margin: 40px 0;

    .table-row {
      display: table-row;

      &:last-child {
        .table-col {
          border-bottom: 1px solid #cecece;
        }
      }

      .table-col {
        display: table-cell;
        padding: 5px 10px;
        border-left: 1px solid #cecece;
        border-top: 1px solid #cecece;
        width: 33%;

        &:last-child {
          border-right: 1px solid #cecece;
        }
      }

      &.row-header {
        font-weight: bold;
        color: #0a1e3f;
        background: #ececec;
      }
    }
  }
  .white-bg {
    background: #ffffff;
    
    /*Temporary*/
    padding: 60px 0;
    text-align: center;
    /*Temporary end*/
  }
  .gray-bg {
    background: #F5F5F5;
    padding: 0px 0 1px !important;
    max-width: initial !important;

    .grid {
      margin-bottom: 10px !important;

      ${bp.computer} {
        margin-bottom: 100px !important;
      }
    }
    ${bp.computer} {
      padding: 60px 0 !important;
    }
  }

`

const CardTitle = styled(Script)`
  margin-bottom: 22px;
`;

const StyledGrid = styled(Grid)`
  &&& {
    padding-top: 50px;
    margin-bottom: 30px;

    ${bp.tablet} {
      margin-bottom: 100px;
    }

    ${bp.computer} {
      padding-top: 0;
      margin-bottom: 100px;
    }
  }
`;

const Station = styled.div`
  ul{
        padding: 0;
  }
  li {
    background-position: left center;
    background-repeat: no-repeat;
    padding: 8px 0 8px 35px;
    margin-bottom: 8px;
    background-image: url(${dotIcon});
    background-position: left 0px center;
    background-size: 25px;
    list-style: none;
  }
`;

const Title = styled.div`
  margin-bottom: 20px;

  ${bp.computer} {
    margin-bottom: 20px;
  }
`;

const Paragraph = styled.p`
  display: block;

`;


const WhiteBox = styled.div`
  background: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 50px 25px 50px;
  max-width: 1560px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 40px;

  ${bp.tablet} {
    padding: 50px 40px 50px;
  }

  &.stamp {
    padding: 10px 25px 50px;
    margin-bottom: 0;

    ${bp.tablet} {
      padding: 10px 50px 50px;
    }
    ${bp.computer} {
      padding: 50px 100px 70px;
      margin: 40px auto 0;
    }
  }
  &.dflex {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    ${bp.computer} {
      flex-direction: row;
    }

    & > div {
      width: 100%;
      max-width: inherit;
      margin-bottom: 40px;

      ${bp.computer} {
        max-width: 320px;
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          border-bottom: 1px solid #f1f1f1;

          color: rgba(0, 0, 0, 0.5);

          a {
            padding: 15px 0;
            display: block;
            color: rgba(0, 0, 0, 0.5);
            text-decoration: none;

            &:hover, &:hover {
              color: rgba(0, 0, 0, 1);
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  &.animateBox {

    /* ${bp.computer} {
      opacity: 0;
    } */
  }

  ${bp.computer} {
    margin: 40px auto 0;
  }
`;





const HelpToBuyPage = ({data}) => { 
  const {
    pages: { helptobuy },
  } = data;
  var content = {};
  content.title = 'Help to buy homes';
  content.type = 'HelpToBuy';

  return (
   <Media
        breakpoint={bp.computer}
        renderIfTrue={() => (



  <Layout onVisible={sellPageMotion}>
    <SEO title={helptobuy.seo.title} description={helptobuy.seo.meta} keywords={helptobuy.seo.keywords} />

    <Container>
      <HelpHero
            content={helptobuy.landing}
          />

      
      <InnerContainer className="gray-bg">



       <Spacer />

       <ViewportEnterAnimation offset={100}>
          <StyledGrid>


              <Spacer />

              <Column width={10}>
                <div className="content-in">
                  <CardTitle delta color="main" block>{helptobuy.second.title}</CardTitle>
                  <Sans alpha color="gray">
                   
                    <Paragraph>{helptobuy.second.bodyText}</Paragraph>
                  </Sans>
                  <br />
                  <Station>
                   <div dangerouslySetInnerHTML={{__html: helptobuy.second.points}} />
                   </ Station>
                </div>
              </Column>

              <Spacer />


          </StyledGrid>

        </ViewportEnterAnimation>

        <ViewportEnterAnimation offset={100} >
          <div className="transparentBG">
            
            <AboutOptions data={helptobuy.faq}/>
          </div>
        </ViewportEnterAnimation>

      

        </InnerContainer>


        <InnerContainer >
          <HelpToBuySlider content={content} />
        </InnerContainer>

      <InnerContainer className="gray-bg">
        <ViewportEnterAnimation offset={100} >
          <StyledGrid>



            <Spacer />
            <Column width={10}>

              <WhiteBox className="animateBox dflex">
              <div>
                  <CardTitle delta color="main" block>First time buyers</CardTitle>
                  <ul>
                    <li>
                      <a href="/buy/">Our buyers guide</a>
                    </li>
                    <li>
                      <a href="#">How much can I borrow?</a>
                    </li>
                    <li>
                      <a href="/buy/">Looking to buy off-plan?</a>
                    </li>
                    <li>
                      <a href="/new-homes/">View our new developments</a>
                    </li>
                  </ul>
                </div>
                <div>
                  <CardTitle delta color="main" block>Moving home</CardTitle>
                  <ul>
                    <li>
                      <a href="/search-results?priceMin=100000&priceMax=1000000&rangeMin=100000&rangeMax=1000000&enquiryType=Buy&area=&propertyType=null&bedrooms=null&more=1-option,2-option&includeNewHomes=true&sortBy=price&sortOrder=DESC&stepSize=10000&page=1">Find your ideal Property</a>
                    </li>
                    <li>
                      <a href="#">Find out what your home is worth</a>
                    </li>
                    <li>
                      <a href="/property-alerts/">Register for property alerts</a>
                    </li>
                    <li>
                      <a href="/new-homes/">See our new homes</a>
                    </li>
                  </ul>
                </div>
                <div>
                  <CardTitle delta color="main" block>Help for buyers</CardTitle>
                  <ul>
                    <li>
                      <a href="/stamp-duty-calculator/">Stamp Duty Calculator</a>
                    </li>
                    <li>
                      <a href="/about/">Why choose Ernest Brooks?</a>
                    </li>
                    <li>
                      <a href="#">Mortgage Advice</a>
                    </li>
                    <li>
                      <a href="/new-homes/">Why buy a new build property?</a>
                    </li>
                  </ul>
                </div>
              </WhiteBox>
            </Column>

            <Spacer />

         </StyledGrid>
        </ViewportEnterAnimation>

      </InnerContainer>

     
      
    </Container>

    {/* <ViewportEnterAnimation offset={100} animation={functionSinglePropertyAdvice}>
      <SinglePropertyAdvice content={callToAction} onClick={this.ctaClick} />
    </ViewportEnterAnimation> */}
  </Layout>
 )}
      renderIfFalse={() => (

  <Layout onVisible={sellPageMotion}>
    <SEO title="Stamp Duty Calculator" />
    <Container>
      <HelpHero
        content={helptobuy.landing}
      />

      <InnerContainer className="gray-bg">

        <Spacer />

        <StyledGrid>


          <Spacer />

          <Column width={10}>
            <div className="content-in">
              <CardTitle delta color="main" block>{helptobuy.second.title}</CardTitle>
              <Sans alpha color="gray">
                <Paragraph>{helptobuy.second.bodyText}</Paragraph>
              </Sans>
              <br />

               <Station>
                   <div dangerouslySetInnerHTML={{__html: helptobuy.second.points}} />
                </ Station>
            </div>
          </Column>

          <Spacer />


          </StyledGrid>

          <ViewportEnterAnimation offset={100} animation={functionAboutAccordion}>
            <div className="transparentBG">
              <AboutOptions data={helptobuy.faq}/>
            </div>
          </ViewportEnterAnimation>

        </InnerContainer>

        <InnerContainer >
        
          <HelpToBuySlider content={content} />

        </InnerContainer>

        <InnerContainer className="gray-bg">
          
          <StyledGrid>


            <Column width={12}>

              <WhiteBox className="dflex">
              <div>
                  <CardTitle delta color="main" block>First time buyers</CardTitle>
                  <ul>
                    <li>
                      <a href="/buy/">Our buyers guide</a>
                    </li>
                    <li>
                      <a href="#">How much can I borrow?</a>
                    </li>
                    <li>
                      <a href="/buy/">Looking to buy off-plan?</a>
                    </li>
                    <li>
                      <a href="/new-homes/">View our new developments</a>
                    </li>
                  </ul>
                </div>
                <div>
                  <CardTitle delta color="main" block>Moving Home</CardTitle>
                  <ul>
                    <li>
                      <a href="/search-results?priceMin=100000&priceMax=1000000&rangeMin=100000&rangeMax=1000000&enquiryType=Buy&area=&propertyType=null&bedrooms=null&more=1-option,2-option&includeNewHomes=true&sortBy=price&sortOrder=DESC&stepSize=10000&page=1">Find your ideal Property</a>
                    </li>
                    <li>
                      <a href="#">Find out what your home is worth</a>
                    </li>
                    <li>
                      <a href="/property-alerts/">Register for property alerts</a>
                    </li>
                    <li>
                      <a href="/new-homes/">See our new homes</a>
                    </li>
                  </ul>
                </div>
                <div>
                  <CardTitle delta color="main" block>Help for Buyers</CardTitle>
                  <ul>
                    <li>
                      <a href="/stamp-duty-calculator/">Stamp Duty Calculator</a>
                    </li>
                    <li>
                      <a href="/about/">Why choose Ernest Brooks?</a>
                    </li>
                    <li>
                      <a href="#">Mortgage Advice</a>
                    </li>
                    <li>
                      <a href="/new-homes/">Why buy a new build property?</a>
                    </li>
                  </ul>
                </div>
              </WhiteBox>
            </Column>


          </StyledGrid>

      </InnerContainer>
    </Container>

    {/* <ViewportEnterAnimation offset={100} animation={functionSinglePropertyAdvice}>
      <SinglePropertyAdvice content={callToAction} onClick={this.ctaClick} />
    </ViewportEnterAnimation> */}

  </Layout>



       )} />
  );
}



export const query = graphql`
  query HelpToBuy {
    pages {
      helptobuy {
        pageTitle
        landing {
          bodyText
          img
        }
        second{
          title
          bodyText
          points
        }

        faq {
          questions {
            question
            answer
          }
        }
        seo{
          title
          keywords
          meta
        }
      }
    }
  }
`;
export default HelpToBuyPage
